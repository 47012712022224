<template>
	<div id="el-heat-wrap"> 

		<v-container>
			<div class="pl-4 pr-4">
			<v-row>
				<v-col>
					<img :src="lightningIcon" alt="fire" class="mr-1" id="ico-lightning">
					<span id="costs">KULUD</span>
				</v-col>
				<v-col class="text-right" id="area">
					<div >
						<span>440</span>
						<span class="m2">m2 maja</span>
					</div>
					<!-- <span>kohta)</span> -->
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col class="pt-4">
					<small>Maasoojus</small>
				</v-col>
			</v-row>
			<v-row class="mt-0">
				<v-col class="pt-1 pb-1">
					<span>Energiakulu</span>
				</v-col>
				<v-col class="pt-1 pb-1 text-right">
					<span>{{totalConsumtion}} kWh</span>
				</v-col>
			</v-row>
			<v-row class="highlight-ground">
				<v-col class="pt-1 pb-1">
					<span>Summa kokku</span>
				</v-col>
				<v-col class="pt-1 pb-1 text-right">
					<span>{{parseInt(totalPrice)}}€</span> <!-- (totalPrice/440) .toFixed(2) -->
				</v-col>
			</v-row>
			</div>

		</v-container>

		<v-container  class="border-gray" id="comparison"> 
			<v-row>
				<v-expansion-panels accordion flat>
					<v-expansion-panel>
						<v-expansion-panel-header>Võrdlus elektriküttega</v-expansion-panel-header>
						<v-expansion-panel-content>
								<v-row class="mt-0">
									<v-col class="pt-1 pb-1">
										<span>Energiakulu</span>
									</v-col>
									<v-col class="pt-1 pb-1 text-right">
										<span>{{parseInt(totalConsumtionEH)}} kWh</span> 
									</v-col>
								</v-row>
								<v-row>
									<v-col class="pt-1 pb-1">
										<span>Summa kokku</span>
									</v-col>
									<v-col class="pt-1 pb-1 text-right">
										<span>{{parseInt(totalPriceEH)}}€</span>
									</v-col>
								</v-row>
								<v-row class="highlight-electric">
									<v-col class="pt-1 pb-1">
										<span>Sinu võit</span>
									</v-col>
									<v-col class="pt-1 pb-1 text-right">
										<span>{{parseInt(totalWin)}}€</span>
									</v-col>
								</v-row>							

						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-row>
		</v-container> 
	</div>
</template>



<script>
 
	import Lightning from "../assets/Lightning.svg";

	export default { 
		props: {
			kwhConsumed: Number, 
			kwhPrice: Number, 
			kwhMultiplierForElectricHeat: Number
		},

		mounted() {   
			this.setDisplayCalculations(); 
		},

		watch: {
			kwhConsumed: function(){ this.setDisplayCalculations(); },
			kwhPrice: function(){ this.setDisplayCalculations(); },
			kwhMultiplierForElectricHeat: function(){ this.setDisplayCalculations(); }
		},

		methods: {
			setDisplayCalculations: function(){
				//1
				this.totalConsumtion = this.kwhConsumed;  
				this.totalPrice = (this.kwhPrice * this.totalConsumtion) / 100;	  //wtf
				//2
				this.totalConsumtionEH = this.kwhConsumed * this.kwhMultiplierForElectricHeat; 
				this.totalPriceEH = (this.kwhPrice * this.totalConsumtionEH) / 100;	//wtf
				//2.2
				this.totalWin = this.totalPriceEH - this.totalPrice;
			}

		},

		data: () => ({

			lightningIcon: Lightning,

			totalConsumtion: null,
			totalPrice: null,
			totalConsumtionEH: null,
			totalPriceEH: null,
			totalWin: null
		})
	}
</script>