<template>
	<div id="temperature-wrap">
		<v-container>
			<v-row>
				<v-col>
					<template v-if="icon=='fire'">
						<img :src="fireIcon" alt="fire"   class="mr-1" id="ico-fire">
					</template>
					<template v-if="icon=='sun'">
						<img :src="sunIcon" alt="sun"  class="mr-1" id="ico-sun">
					</template> 
					<span class="where">{{where}}</span> 
				</v-col>
			</v-row>  
			<v-row class="mt-0" >
				<v-col class="pt-1 pb-1">
					<small class="text-gray city">{{info}}</small> 
				</v-col> 
			</v-row>
			<v-row>
				<v-col class="pt-2 pb-2">
					<span class="temperature">{{temperature}}°C</span>
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col class="pt-2 pb-1 lh-1">
					<small class="text-dark when">Keskmine perioodi kohta</small>
				</v-col>
			</v-row> 			

		</v-container>
	</div>
</template>



<script>

	import Fire from "../assets/Fire.svg";
	import Sun from "../assets/Sun.svg";

	export default {
		props: ['icon', 'where', 'info', 'temperature'],

		mounted() {

		},

		watch: {
		},

		methods: {

		},

		data: () => ({
			fireIcon: Fire,
			sunIcon: Sun,
		})
	}
</script> 